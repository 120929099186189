/**
 * Horizontally/vertically centred spinner with random quirky message.
 */

import React, { useRef, useLayoutEffect, useState } from 'react';
import { css } from '@emotion/react';
import { memo } from 'react';
import { Spin as AntdSpin } from 'antd';
import { getMathRandomFromString } from '../utils/crypto';

const spinCss = css`
  /* Ensure nothing touches these absolute properties */
  i {
    top: unset !important;
    left: unset !important;
  }
`;

// Mostly stolen shamelessly from the internet
const loadingMessages = [
  'Loading...',
  'Summoning more ziggurats...',
  'Reticulating splines...',
  'Winter is coming...',
  'Spinning up hamster wheel...',
  'Shovelling coal into server...',
  'Follow the white rabbit...',
  'Charging flux capacitor...',
  'Testing your patience...',
  'Dreaming up electric sheep...',
  'Serving your fries...',
  'Leaving Kansas...',
  'Are we there yet?',
  'Dusting out cobwebs...',
  'Proving Riemann Hypothesis...',
  'Constructing additional pylons...',
  'Preparing succulent Chinese meal...',
  'Walking into Mordor...',
  'Going to Bonnie Doon...',
  'Gathering more vespene gas...',
  'Lisa needs braces! Dental plan!',
  'The cake is a lie...',
  'No soup for you!',
  "How's the serenity...",
  'Boiling kettle...',
  'Everything is fine...',
  'Reaching vapour-liquid equilibrium...',
  'Entering the Matrix...',
  'Warming up vacuum tubes...',
  'Serving gabagool...',
  'Picking up power converters...',
  "It's working, it's working!",
  'Your call is important to us...',
  'Reviewing submission...',
  'Did you ever hear the tragedy of Darth Plagueis The Wise?',
  '... 42!',
  'So long, and thanks for all the fish...',
  'Calling Saul...',
  'Who ya gonna call?',
  "Making an offer you can't refuse...",
  'The truth is out there...',
  'Hold my coffee...',
  'Back in 10...',
  'Inverting time and space...',
  'Squaring the circle...',
  'Initialising elevator music...',
  'Have you lost weight?',
  "Don't panic...",
  'This is the way...',
];

// Register of spinners, including their { clientRect, seed, now }.
const spinRegister = [];

// Get seed from any overlapping spinners, or return new seed.
const GET_SEED_TIMEOUT = 4_000;
const getSeed = ({ clientRect }) => {
  console.log('getSeed');
  const seed = Math.random().toString();
  if (!clientRect) return seed;
  const now = Date.now();
  console.log('spinRegister loop...');
  for (let spin of spinRegister) {
    console.log(spin);
    const spinRect = spin.clientRect;
    const overlap = !(
      clientRect.right < spinRect.left ||
      clientRect.left > spinRect.right ||
      clientRect.bottom < spinRect.top ||
      clientRect.top > spinRect.bottom
    );
    const withinTimeout = now - spin.now < GET_SEED_TIMEOUT;
    if (overlap && withinTimeout) {
      spin.now = now;
      return spin.seed;
    }
  }
  spinRegister.push({ clientRect, seed, now });
  return seed;
};

let Spin = ({ randomSeed, style, ...spinProps }) => {
  const containerRef = useRef();
  const [seed, setSeed] = useState(randomSeed);
  const [heightOkay, setHeightOkay] = useState(false);
  // TODO: Reduce flickering upon rerendering. Below is one attempt but it
  // still has a (smaller) flicker, and different spinners in the same spot
  // have the same message, which is not always desirable (e.g. charts page).
  // useEffect(() => {
  //   if (ref?.current && !seed) {
  //     console.log('getClientRect');
  //     const clientRect = ref.current.getBoundingClientRect();
  //     const newSeed = getSeed({ clientRect });
  //     setSeed(newSeed);
  //   }
  // });
  // Only want to show a message if there is room
  useLayoutEffect(() => {
    if (containerRef?.current?.clientHeight > 36) setHeightOkay(true);
  });
  const mathRandom = seed ? getMathRandomFromString(String(seed)) : Math.random;
  const message =
    loadingMessages[Math.floor(mathRandom() * loadingMessages.length)];
  return (
    <div
      ref={containerRef}
      css={spinCss}
      className="tentacle-spin"
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        ...(style || {}),
      }}
    >
      <div style={{ margin: 'auto' }}>
        <AntdSpin
          tip={
            <span style={{ fontSize: '0.85rem' }}>
              {heightOkay ? message : ''}
            </span>
          }
          {...spinProps}
        />
      </div>
    </div>
  );
};

Spin = memo(Spin);

export default Spin;
