/**
 * Override using Tentacle Spin component as the loader image.
 */

import React, { memo } from 'react';
import { styled } from '@superset-ui/core';
import cls from 'classnames';
import Spin from 'src-tentacle/components/Spin';

const LoaderImg = styled.div`
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba(256, 256, 256, 0.8);
  position: relative;
  margin: 10px;
  &.inline {
    margin: 0px;
    width: 30px;
  }
  &.inline-centered {
    margin: 0 auto;
    width: 30px;
    display: block;
  }
  &.floating {
    padding: 0;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

let Loading = ({ position = 'floating', image, className }) => {
  return (
    <LoaderImg
      className={cls('loading', position, className)}
      role="status"
      aria-live="polite"
      aria-label="Loading"
      data-test="loading-indicator"
    >
      <Spin />
    </LoaderImg>
  );
};

Loading = memo(Loading);

export default Loading;
