/**
 * FUnctions related to encrypting/decryption integer IDs to base 32 strings.
 */

// See https://stackoverflow.com/a/47593316/7978198
export function cyrb128(str) {
  let h1 = 1779033703,
    h2 = 3144134277,
    h3 = 1013904242,
    h4 = 2773480762;
  for (let i = 0, k; i < str.length; i++) {
    k = str.charCodeAt(i);
    h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
    h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
    h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
    h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
  }
  h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
  h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
  h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
  h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
  return [
    (h1 ^ h2 ^ h3 ^ h4) >>> 0,
    (h2 ^ h1) >>> 0,
    (h3 ^ h1) >>> 0,
    (h4 ^ h1) >>> 0,
  ];
}

// Deterministic pseudorandom `Math.random` replacement.
// See https://stackoverflow.com/a/47593316/7978198
export function xoshiro128ss(a, b, c, d) {
  return function () {
    var t = b << 9,
      r = a * 5;
    r = ((r << 7) | (r >>> 25)) * 9;
    c ^= a;
    d ^= b;
    b ^= c;
    a ^= d;
    c ^= t;
    d = (d << 11) | (d >>> 21);
    return (r >>> 0) / 4294967296;
  };
}

export const getMathRandomFromString = str => {
  const seed = cyrb128(str.toString());
  return xoshiro128ss(seed[0], seed[1], seed[2], seed[3]);
};

// Rotate (shift) an array by steps
const rotate = (arr, steps = 1) => {
  return [...arr.slice(steps, arr.length), ...arr.slice(0, steps)];
};

// Shuffle an array (see https://stackoverflow.com/a/12646864/7978198)
function shuffle(array, random = Math.random) {
  let _array = [...array.slice()];
  for (let i = _array.length - 1; i > 0; i--) {
    const j = Math.floor(random() * (i + 1));
    [_array[i], _array[j]] = [_array[j], _array[i]];
  }
  return _array;
}

/**
 * Simple base 32 encryption/decryption class built to convert integer IDs to
 * base 32 strings of fixed length. Not built in any way to pass randomness
 * tests, just to give reasonably different output when integer IDs are close.
 * In fact, it will appear less random the closer the length of the radix 30
 * representation of the input number gets to the fixed length minus 2.
 */
export class Base32Crypt {
  constructor(length = 12, seed = 'change-me') {
    this.length = length;
    this.seed = seed;
    // Define useful constants/lists/maps
    this._crockfordSet = '0123456789abcdefghjkmnpqrstvwxyz'.split('');
    this._radix30Set = '0123456789abcdefghijklmnopqrst'.split('');
    this._radix30ToCrockford = Object.fromEntries(
      this._radix30Set.map((a, i) => [a, this._crockfordSet[i]]),
    );
    this._crockfordToRadix30 = Object.fromEntries(
      this._radix30Set.map((a, i) => [this._crockfordSet[i], a]),
    );
    // Use the seed to generate a Vigenere "Square" which consists of
    // `length` different ciphers for the base 32 character set.
    this._initRandom(this.seed);
    this._vigenereSquare = [];
    this._inverseVigenereSquare = [];
    [...Array(this.length)].forEach(_ => {
      const shuffled = shuffle(this._crockfordSet, this.random);
      this._vigenereSquare.push(
        Object.fromEntries(this._crockfordSet.map((a, i) => [a, shuffled[i]])),
      );
      this._inverseVigenereSquare.push(
        Object.fromEntries(this._crockfordSet.map((a, i) => [shuffled[i], a])),
      );
    });
  }

  _initRandom(seed) {
    this.random = getMathRandomFromString(seed);
  }

  encrypt(input) {
    // Initial string is "y<radix30>z<padding>" where radix is the radix-30
    // representation of the input number using the first 30 Crockford
    // characters (no y or z), and padding is (deterministic) random first
    // 30 Crockford characters (no y or z) with seed depending on input.
    const radix30 = Number(input)
      .toString(30)
      .split('')
      .map(r => this._radix30ToCrockford[r]);
    // Use the input to initialise the random number generator for padding
    this._initRandom(`${input}${this.seed}`);
    const padLength = this.length - 2 - radix30.length;
    const pad = [...Array(padLength)].map(
      _ => this._crockfordSet.slice(0, -2)[Math.floor(this.random() * 30)],
    );
    let array = ['y', ...radix30, 'z', ...pad];
    // We now rotate the array a (deterministic) random fixed number of
    // steps. This helps with appearance of randomness.
    const rotateSteps = Math.floor(this.random() * this.length);
    array = rotate(array, rotateSteps);
    // Encode according to Vigenere
    array = array.map((a, i) => this._vigenereSquare[i][a]);
    return array.join('');
  }

  decrypt(output) {
    // Decode according to Vigenere
    let array = output
      .split('')
      .map((a, i) => this._inverseVigenereSquare[i][a]);
    // Find the characters between 'y' and 'z'
    const leftIndex = array.indexOf('y');
    array = rotate(array, leftIndex + 1);
    // Inverse radix them
    const radix30 = array.join('').split('z')[0];
    // Parse the radix string as a base-10 integer
    return parseInt(
      radix30
        .split('')
        .map(r => this._crockfordToRadix30[r])
        .join(''),
      30,
    ).toString();
  }
}

// Encryptors for various integer IDs
export const databaseCrypt = new Base32Crypt(12, 'database-seed');
export const datasetCrypt = new Base32Crypt(12, 'dataset-seed');
export const tableCrypt = new Base32Crypt(12, 'table-seed');
export const chartCrypt = new Base32Crypt(12, 'chart-seed');
export const dashboardCrypt = new Base32Crypt(12, 'dashboard-seed');

// Async sha256 method
// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#Converting_a_digest_to_a_hex_string
export const sha256 = async string => {
  const utf8 = new TextEncoder().encode(string);
  return crypto.subtle.digest('SHA-256', utf8).then(hashBuffer => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map(bytes => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  });
};
