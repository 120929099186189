import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { Provider as ReduxProvider } from 'react-redux';
import { GlobalStyles } from 'src/GlobalStyles';
import { ThemeProvider } from '@superset-ui/core';
import { theme } from 'src/preamble';
import { store } from 'src/views/store';

export const Providers = ({ children }) => (
  <Router>
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <GlobalStyles />
          {children}
        </QueryParamProvider>
      </ReduxProvider>
    </ThemeProvider>
  </Router>
);
