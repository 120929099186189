/**
 * Patch the default error message display to handle 403 and 429 responses
 * to give a friendlier message and prompt the user to sign up.
 */

import React, { memo } from 'react';
import { t } from '@superset-ui/core';
import ErrorAlert from '../../../../src/components/ErrorMessage/ErrorAlert';
import getErrorMessageComponentRegistry from '../../../../src/components/ErrorMessage/getErrorMessageComponentRegistry';

import { Alert } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { USER_ID } from 'src-tentacle/utils/privileges';

const DEFAULT_TITLE = t('Unexpected error');

const _getApology = () => {
  const options = [
    'Sorry!',
    'Oops!',
    'Too bad...',
    'Oh no!',
    'Fiddlesticks!',
    'Ah...',
    'Drat!',
    'Crikey!',
    'Whoa there!',
    'Hold up!',
  ];
  return options[Math.floor(Math.random() * options.length)];
};

const copyTextToMessageDescription = copyText => {
  if (copyText == 'Forbidden') {
    return {
      message: copyText,
      description: () => (
        <>
          {_getApology()} You are not authorised to access this resource.{' '}
          {!USER_ID ? (
            <>
              <a href="/auth/login">Login</a> or{' '}
              <a href="/auth/registration">register</a> to access public
              datasets.
            </>
          ) : (
            <>
              Please contact us to enquire about access!
            </>
          )}
        </>
      ),
    };
  }
  if (copyText == 'Too Many Requests') {
    return {
      message: 'Too Many Requests',
      description: () => (
        <>
          {_getApology()} You've surpassed your quota for the day. Come back
          tomorrow to continue your public access. Please contact us if you need
          more!
        </>
      ),
    };
  }
  if (copyText.includes('timed out') || copyText.includes('Time-out')) {
    return {
      message: 'Timed Out',
      description: () => (
        <>
          {_getApology()} The query may be heavy or the server is under load.
          Try forcing a refresh using the chart menu.
        </>
      ),
    };
  }
  return null;
};

let ErrorMessageWithStackTrace = ({
  title = DEFAULT_TITLE,
  error,
  subtitle,
  copyText,
  link,
  stackTrace,
  source,
  description,
}) => {
  // Our custom logic.
  const messageDescription = copyTextToMessageDescription(copyText);
  if (messageDescription) {
    return (
      <div style={{ height: '100%', overflowY: 'scroll' }}>
        <Alert
          message={
            <>
              <InfoCircleOutlined /> {messageDescription.message}
            </>
          }
          description={messageDescription.description()}
          type="info"
          style={{ padding: '8px 12px', marginTop: '4px' }}
        />
      </div>
    );
  }

  // Check if a custom error message component was registered for this message
  if (error) {
    const ErrorMessageComponent = getErrorMessageComponentRegistry().get(
      error.error_type,
    );
    if (ErrorMessageComponent) {
      return (
        <ErrorMessageComponent
          error={error}
          source={source}
          subtitle={subtitle}
        />
      );
    }
  }

  return (
    <ErrorAlert
      level="warning"
      title={title}
      subtitle={subtitle}
      copyText={copyText}
      description={description}
      source={source}
      body={
        link || stackTrace ? (
          <>
            {link && (
              <a href={link} target="_blank" rel="noopener noreferrer">
                (Request Access)
              </a>
            )}
            <br />
            {stackTrace && <pre>{stackTrace}</pre>}
          </>
        ) : undefined
      }
    />
  );
};

ErrorMessageWithStackTrace = memo(ErrorMessageWithStackTrace, (p, n) => {
  return (
    p.title == n.title &&
    p.error == n.error &&
    // p.subtitle == n.subtitle &&
    p.copyText == n.copyText &&
    p.link == n.link &&
    p.stackTrace == n.stackTrace &&
    p.source == n.source &&
    p.description == n.description
  );
});

export default ErrorMessageWithStackTrace;
